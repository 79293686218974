import React from "react";

import AccountsApex from "@/game-apex/Accounts.jsx";
import { PageContainer } from "@/hub-settings/Common.style.jsx";

export default function ApexSettings() {
  return (
    <PageContainer>
      <AccountsApex />
    </PageContainer>
  );
}
